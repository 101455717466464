import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Box, Text } from "../Core";
import Logo from "../Logo";
import { device } from "../../utils";

import FacebookIcon from "../../assets/peel-creative/social-icons/facebook.svg"
import InstagramIcon from "../../assets/peel-creative/social-icons/insta.svg"
import LinkedInIcon from "../../assets/peel-creative/social-icons/linkedin.svg"
import TwitterIcon from "../../assets/peel-creative/social-icons/twitter.svg"
import { Link } from "gatsby";

const TitleStyled = styled(Title)`
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0px;
  margin-bottom: 15px;

  @media ${device.sm} {
    font-size: 18px;

  }

`;

const TitleStyledNM = styled(Text)`
  color: black !important;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media ${device.sm} {
    border-top: 1px solid #9fc0c5;
    margin-top: 0px;
    padding-top: 0rem;
  }


`;


const UlStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    line-height: 2.25;
    a {
      color: ${({ theme, color }) => theme.colors[color]} !important;
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`;


const StyledBox = styled(Box)`
.social-icons{
  display: flex;
  padding-bottom: 10px;
}

.social-icons img{
  padding-right: 10px;
}
.f-bigger{
  font-size: 30px;
  line-height: 40px;
  font-weight: 300;
}


.f-light{
  font-weight: 300;
}


.f-light a{
  font-weight: 300;

}
.f-bold{
  font-weight: bold;
}
@media ${device.sm} {

.m-fix{
  padding-top: 80px;
}
}
`

const Footer = ({ isDark = true }) => {
  return (
    <>
      {/* <!-- Footer section --> */}
      <StyledBox bg="#9fc0c5">
        <Container>
          <Box
            css={`
              padding: 80px 0 60px;
            `}
          >
            <Row className="justify-content-center">
              <Col lg="5" md="5">
                <Logo white={isDark} />
                <Text className='pt-2 f-bigger' color="black">Creating your brand and<br/>
bringing your vision to life!</Text>
              </Col>
              <Col lg="7" md="7" className="mt-5 mt-lg-0">
                <Row>
                  <Col xs="6" lg="3">
                    <div className="mb-lg-4">
                      <Link to="/about">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "black"}
                      >
                        About
                      </TitleStyled>
                      </Link>
                      <Link to="/#work">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "black"}
                      >
                        Work
                      </TitleStyled>
                      </Link>

                      <Link to="/contact">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "black"}
                      >
                        Contact
                      </TitleStyled>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Box>
          <Box        css={`
              padding: 0px 0 60px;
            `}
>
            <Row>
              <Col lg="5" md="5">
              <div>
<div className='social-icons'>
<a href="https://www.facebook.com/peeldesign/"><img height="45px" src={FacebookIcon}/></a>
<a href="https://www.instagram.com/peeldesign715/?hl=en"><img height="45px" src={InstagramIcon}/></a>
<a href="https://www.linkedin.com/in/rita-palmieri/"><img height="45px" src={LinkedInIcon}/></a>

</div>
<Text color="white" className='f-bold' pb={1} pt={3}>Peel Design Co</Text>

<Text color="white" pb={1} className='f-light'>Carlton North 3054, Victoria</Text>
<a href="mailto:rita@peeldesign.com.au" className='f-light' style={{fontWeight: "300 !important"}}><Text color="white" style={{fontWeight: "300"}} pb={1}>rita@peeldesign.com.au</Text></a>
<a href="tel:+61 407 357 267"><Text color="white" css={{fontWeight: "bold"}} pb={1}>0407 357 267</Text></a>


</div>

              </Col>
              <Col lg="5" md="5" className='d-flex m-fix' style={{alignItems: "flex-end"}}>
                <div>
                <TitleStyledNM pb={2}>
                <span css={{fontWeight: "bold"}}>2021 Peel Design Co</span>
                </TitleStyledNM>
                <TitleStyledNM>
                All rights reserved. Website developed by <a href="https://tensq.com.au" target="_blank" css={{display: "contents"}}>Ten Squared</a>
                </TitleStyledNM>
                </div>

              </Col>

            </Row>
          </Box>
        </Container>
      </StyledBox>
    </>
  );
};

export default Footer;
