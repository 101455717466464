export const menuItems = [
  {
    name: "about",
    label: "About",
  },
  {
    name: "#work",
    label: "Work",
  },
  {
    name: "contact",
    label: "Contact",
  },

];
